 /* add/remove class active for nav */
 export function setActiveUrl(lang_code) {
    let urlLocation = $('body').data('page-name');
    $('.header-top ul li').find('active').removeClass('active');

    switch (urlLocation) {
        case "hp":
            $('.home-url.main-link').addClass('active');
            break;
        case "getting-started":
        case "education-center":
            $('.gs-url.main-link').addClass('active');
            break;
        case "welcome-package":
        case "psv-raffle":
        case "education-package":
        case "pro-package":
        case "friend-bring-friend":
        case "refer-a-friend":
        case "promotions":
            $('.promotions-url.main-link, .promotions-url .main-link').addClass('active');
            break;
        case "blog-hub":
            $('.blog-url.main-link').addClass('active');
            break;
        case "stocks":
        case "fixed-interest-account":
        case "economic-calendar":
        case "trading-tools":
        case "trading-platforms":
        case "trade-safe":
        case "trading-insights":
        case "live-rates":
        case "daily-video-analysis":
        case "follow-our-experts":
        case "currencies-hub":
        case "commodities-hub":
        case "shares-hub":
        case "indices-hub":
        case "etfs-hub":
        case "crypto-hub":
        case "non-leveraged-products-hub":
        case "payments":
        case "blog-hub":
            $('.products-url.main-link').addClass('active');
            break;
        case "contact-us":
            if(lang_code == 'pt'){
                $('.contact-url.main-link').addClass('active');
            }
            break;
        case "about-us":
            if(lang_code == 'pt'){
                $('.about-url.main-link').addClass('active');
            }
            break;
        case "partner-programs":
        case "green-trader":
            $('.our-company-url.main-link').addClass('active');
            break;
        case "ib-offers":
            $('.ibs-url .main-link').addClass('active');
            break;
        case "news":
        case "news-archive":
        case "authors":
        case "today-in-the-market":
        case "market-analysis":
        case "breaking-news":
            $('.news-url .main-link').addClass('active');
            if(lang_code == 'cn'){
                $('.products-url.main-link').addClass('active');
            }
        default:
        // only if case does not exist
    }
}